<template>
  <v-list>
    <template
      v-for="(node, index) in nodes"
    >
      <v-list-group
        v-if="node.children && node.children.length"
        :key="index"
        v-model="isOpen"
      >
        <v-list-item
          slot="activator"
        >
          <v-list-item-action>
            <v-checkbox
              v-model="selection"
              :value="node.path"
              color="#009688"
            />
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title v-if="node.name === bucketName">
              {{ node.name }} (pasta raiz)
            </v-list-item-title>
            <v-list-item-subtitle>{{ node.path }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <node
          class="py-0 pl-3"
          :nodes="node.children"
        />
      </v-list-group>
      <v-list-item
        v-else
        :key="index"
      >
        <v-list-item-action>
          <v-checkbox
            v-model="selection"
            :value="node.path"
            color="#009688"
          />
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ node.name }}</v-list-item-title>
          <v-list-item-subtitle>{{ node.path }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-list>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  export default {
    name: 'Node',
    props: {
      nodes: {
        type: Array,
        required: true,
      },
    },
    data: () => {
      return {
        localSelection: '',
        isOpen: true,
      }
    },
    computed: {
      ...mapGetters('files', ['getDirectories', 'bucketName']),
      selection: {
        get: function () {
          if (this.localSelection === this.getDirectories) {
            return this.localSelection
          } else {
            return false
          }
        },
        set: function (newVal) {
          if (newVal === null) {
            this.selection = false
            this.updateDirectories(false)
          } else {
            this.localSelection = newVal
            this.updateDirectories(newVal)
          }
        },
      },
    },
    methods: {
      ...mapActions('files', ['updateDirectories']),
    },
  }
</script>
